import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "mt-4" }
const _hoisted_2 = { class: "d-flex flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormKit = _resolveComponent("FormKit")!
  const _component_Button = _resolveComponent("Button")!
  const _directive_loading = _resolveDirective("loading")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormKit, {
      type: "form",
      actions: false,
      modelValue: _ctx.entity,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.entity) = $event)),
      onSubmit: _ctx.changePassword,
      id: "changePasswordForm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_FormKit, {
          type: "password",
          name: "currentPassword",
          label: "Current password",
          help: "Enter a new password",
          validation: "required"
        }),
        _createVNode(_component_FormKit, {
          type: "password",
          name: "newPassword",
          label: "New password",
          help: "Enter a new password",
          validation: "required"
        }),
        _createVNode(_component_FormKit, {
          type: "password",
          name: "confirmNewPassword",
          label: "Confirm new password",
          help: "Confirm your new password",
          validation: "required|confirm:newPassword",
          "validation-label": "Password confirmation"
        })
      ]),
      _: 1
    }, 8, ["modelValue", "onSubmit"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Button, { onClick: _ctx.submit }, {
        default: _withCtx(() => [
          _createTextVNode("Change Password")
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ])), [
    [_directive_loading, _ctx.isLoading]
  ])
}