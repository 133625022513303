
import {defineComponent} from "vue";
import Button from "@/components/UI/Button.vue";
import {UserService} from "@/services/UserService";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {required, sameAs} from "@vuelidate/validators";

export default defineComponent({
  components: {Button},
  setup() {
    return {v$: useVuelidate()}
  },
  validations(){
    return{
      entity: {
        currentPassword: {required},
        newPassword: {required},
        confirmNewPassword: sameAs(this.entity.newPassword)
      }
    }
  },
  data() {
    return {
      entity: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      formData: {},
      isLoading: false
    }
  },
  methods: {
    submit(){
      this.$formkit.submit('changePasswordForm')
    },
    changePassword() {
      this.isLoading = true
      UserService.changePassword({
        currentPassword: this.entity.currentPassword,
        newPassword: this.entity.newPassword
      }).then(() => {
        this.isLoading = false
        Swal.fire("Password Updated")
      })
    }
  }
})
